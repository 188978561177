export const CategoryName = (category) => {
    let categoryName = "";
    if (category.name && category.name.length > 0) {
      categoryName =
        category.name.some((x) => x.languageCode === "en")
          ? category.name.find((x) => x.languageCode === "en").text
          : category.name[0].text;
    }
    return categoryName;
  };

  export const ProductName = (product) => {
    let productName = "";
    if (product && product.name && product.name.length > 0) {
      productName =
      product.name.some((x) => x.languageCode === "en")
          ? product.name.find((x) => x.languageCode === "en").text
          : product.name[0].text;
    }
    return productName;
  };