import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Tabs,
  Select,
  Modal,
  Spin,
  Divider,
} from "antd";
import api from "./../../Api";
import languages from "../Languages/languages.json";
import slugify from "slugify";
import { ValidateToken } from "../Utils/Validator";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const useCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let categoryID = params.get("categoryID");
      const { data } = await api.categories("All").get();
      setCategories(data.filter((x) => x.id !== parseInt(categoryID)));
    };
    getCategories();
  }, []);
  return {
    categories,
  };
};

const useUpdateForm = (defaultValues, isNew, categories) => {
  const [inputs, setInputs] = useState(
    isNew
      ? {
          code: "",
          name: [],
          description: [],
          slug: "",
        }
      : { ...defaultValues }
  );

  const [modal, setModal] = useState({
    ModalText: "Confirm category delete",
    visible: false,
    confirmLoading: false,
  });

  const handleSubmit = async () => {
    ValidateToken(history);
    if (
      !inputs.name.some((x) => x.languageCode === languages[0].code) ||
      (inputs.name.some((x) => x.languageCode === languages[0].code) &&
        inputs.name.find((x) => x.languageCode === languages[0].code).text ===
          "")
    ) {
      await message.error(`Category name in english is required`, 2);
      return;
    }
    let slug = slugify(
      inputs.name.some((x) => x.languageCode === languages[0].code)
        ? inputs.name.find((x) => x.languageCode === languages[0].code).text
        : inputs.name[0].text,
      {
        lower: true,
      }
    );
    if (categories.some((x) => x.slug === slug)) {
      await message.error(`Category name already exists`, 2);
      return;
    }

    if (categories.some((x) => x.code === inputs.code)) {
      await message.error(`Category code already exists`, 2);
      return;
    }

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let categoryID = params.get("categoryID");

    let data = {
      id: parseInt(categoryID),
      code: inputs.code,
      name: inputs.name,
      description: inputs.description,
      parentId: inputs.parentId,
      slug: slugify(slug, {
        lower: true,
      }),
    };
    await api
      .categories()
      .post(data)
      .then(async (res) => {
        await message.success("This category successfully edited", 1);
        window.location.reload();
      });
  };

  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api.categories(inputs.id).delete();
    await message.warning("This category successfully deleted", 1);
    history.push(`/categories`);
    window.location.reload();
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleTranslateFieldsChange = (event, languageCode) => {
    event.persist();
    let data = event.target.value;

    let tmpArray = inputs[event.target.name] ? inputs[event.target.name] : [];
    tmpArray = tmpArray.filter((x) => x.languageCode !== languageCode);
    tmpArray.push({ languageCode: languageCode, text: data });
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: tmpArray,
    }));
  };

  const handleCategoryChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      parentId: value,
    }));
  };

  return {
    handleSubmit,
    handleModalOk,
    handleModalCancel,
    showModal,
    modal,
    handleInputChange,
    handleTranslateFieldsChange,
    handleCategoryChange,
    inputs,
  };
};

const CategoryForm = ({ category, isNew }) => {
  const { categories } = useCategories();
  const {
    inputs,
    handleModalOk,
    handleModalCancel,
    showModal,
    modal,
    handleInputChange,
    handleTranslateFieldsChange,
    handleCategoryChange,
    handleSubmit,
  } = useUpdateForm(category, isNew, categories);

  const callback = (key) => {};
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      {languages.map((language) => {
        return (
          <TabPane tab={language.name} key={language.id}>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Form.Item label="Code">
                <Input
                  type="text"
                  name="code"
                  onChange={handleInputChange}
                  value={inputs.code}
                />
              </Form.Item>
              <Form.Item label="Category name">
                <Input
                  type="text"
                  name="name"
                  onChange={(event) =>
                    handleTranslateFieldsChange(event, language.code)
                  }
                  value={
                    inputs.name &&
                    inputs.name.some((x) => x.languageCode === language.code)
                      ? inputs.name.find(
                          (x) => x.languageCode === language.code
                        ).text
                      : ""
                  }
                />
              </Form.Item>
              <Form.Item label="Description">
                <TextArea
                  type="text"
                  name="description"
                  autosize={{ minRows: 3, maxRows: 50 }}
                  onChange={(event) =>
                    handleTranslateFieldsChange(event, language.code)
                  }
                  value={
                    inputs.description &&
                    inputs.description.some(
                      (x) => x.languageCode === language.code
                    )
                      ? inputs.description.find(
                          (x) => x.languageCode === language.code
                        ).text
                      : ""
                  }
                />
              </Form.Item>
              <Form.Item label="Parent category">
                <Select
                  name="categoryId"
                  style={{ width: "100%" }}
                  placeholder="Please select category"
                  onChange={handleCategoryChange}
                  value={inputs.parentId}
                >
                  <Option value={0}>None</Option>
                  {categories.map((cat) => (
                    <Option value={cat.id} key={cat.id}>
                      {cat.name &&
                      cat.name.some((x) => x.languageCode === language.code)
                        ? cat.name.find((x) => x.languageCode === language.code)
                            .text
                        : ""}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Slug">
                <Input
                  name="slug"
                  type="text"
                  onChange={handleInputChange}
                  value={inputs.slug}
                  disabled
                />
              </Form.Item>
              <Divider />
              <Row type="flex" gutter={32}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button type="danger" onClick={showModal}>
                      Delete
                    </Button>
                    <Modal
                      title="Are you sure?"
                      visible={modal.visible}
                      onOk={handleModalOk}
                      confirmLoading={modal.confirmLoading}
                      onCancel={handleModalCancel}
                    >
                      <p>{modal.ModalText}</p>
                    </Modal>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
        );
      })}
    </Tabs>
  );
};

const Category = () => {
  const [category, setCategory] = useState({});
  const [spinning, setSpinning] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";

  useEffect(() => {
    const fetchCategory = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let categoryID = params.get("categoryID");
      const res = await api.categories(categoryID).get();
      setCategory(res.data[0]);
      setSpinning(false);
    };
    fetchCategory();
  }, []);
  return (
    <div className="category">
      <Link className="back-icon" to="/categories">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <Row>
              <Col span={12}>
                <div>
                  <h1>{isNew ? "Add New" : ""}</h1>
                  {isNew ? (
                    <CategoryForm category={category} isNew={isNew} />
                  ) : (
                    !!category.id && (
                      <CategoryForm category={category} isNew={isNew} />
                    )
                  )}
                </div>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Category;
