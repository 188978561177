import axios from "axios";
import EndpointFactory from "axios-endpoints";
import Cookie from "js-cookie";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${
    Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null
  }`;
  config.headers.ContentType = "application/json";
  return config;
});

const Endpoint = EndpointFactory(axiosInstance);

export default {
  images: (id = "") => new Endpoint(`/images/${id}`),
  uploads: (uid = "") => new Endpoint(`/uploads/${uid}`),
  categories: (id = "") => new Endpoint(`/categories/${id}`),
  manufacturers: (id = "") => new Endpoint(`/manufacturers/${id}`),
  products: (id = "") => new Endpoint(`/products/${id}`),
  pages: (id = "") => new Endpoint(`/pages/${id}`),
  blog: (id = "") => new Endpoint(`/posts/${id}`),
  specs: (id = "") => new Endpoint(`/specs/${id}`),
  informations: (id = "") => new Endpoint(`/informations/${id}`),
  prices: (id = "") => new Endpoint(`/prices/${id}`),
  customerPrices: (id = "") => new Endpoint(`/customer-prices/${id}`),
  geoZones: (id = "") => new Endpoint(`/geozones/${id}`),
  getCustomers: (id = "") => new Endpoint(`/auth/get-customers/${id}`),
  getCustomer: (id = "") => new Endpoint(`/customer/${id}`),
  saveCustomer: () => new Endpoint(`/auth/save-customer/`),
  discounts: (id = "") => new Endpoint(`/discounts/${id}`),
  orders: (id = "") => new Endpoint(`/orders/${id}`),
  documents: (id = "") => new Endpoint(`/documents/${id}`),
  documentsInfo: (id = "") => new Endpoint(`/documents-info/${id}`),
  bestSellerProduct: (id = "") => new Endpoint(`/bestseller-product/${id}`),
  actualPrices: (id = "") => new Endpoint(`/actual-prices/${id}`),
};
