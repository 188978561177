import React, { useState, useEffect } from "react";
import { Row, Col, Select, Input, InputNumber, List, Button } from "antd";
import { ProductName } from "../Utils/Common";
import api from "../../Api";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const NewOrder = ({ customers, setNewOrder }) => {
  const [products, setProducts] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [disabledAddButton, setDisabledAddButton] = useState(true);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => {
          setProducts(response.data);
        })
        .catch((err) => console.log(err));
    };
    getProducts();
  }, []);

  const handleCustomerSelect = async (value) => {
    if (value === undefined) {
      setOrderDetails([]);
      setDisabledAddButton(true);
      setNewOrder({});
    } else {
      await api
        .actualPrices(value)
        .get()
        .then((res) => {
          setPrices(res.data);
          setDisabledAddButton(false);
          setNewOrder({ customerUid: value, orderDetails: orderDetails });
        })
        .catch((err) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log(err);
          }
        });
    }
  };

  const setTotals = () => {
    let total = orderDetails.reduce((a, b) => a + b.price * b.quantity, 0);
    setTotalSum(Number(total.toFixed(2)));
  };

  const handleOrderRowDelete = (index) => {
    orderDetails.splice(index, 1);
    setTotals();
    setNewOrder((newOrder) => ({
      ...newOrder,
      orderDetails: orderDetails,
    }));
  };

  const handlePriceChange = (price, index) => {
    orderDetails[index].price = price;
    setTotals();
    setNewOrder((newOrder) => ({
      ...newOrder,
      orderDetails: orderDetails,
    }));
  };

  const handleQuantityChange = (quantity, index) => {
    orderDetails[index].quantity = quantity;
    setTotals();
    setNewOrder((newOrder) => ({
      ...newOrder,
      orderDetails: orderDetails,
    }));
  };

  const handleAddProduct = () => {
    orderDetails.push({ productId: 0, quantity: 1, price: 0 });
    setTotals();
    setNewOrder((newOrder) => ({
      ...newOrder,
      orderDetails: orderDetails,
    }));
  };

  const handleProductSelect = (productId, index) => {
    orderDetails[index].productId = productId;
    if (prices.some((x) => x.productId === productId)) {
      orderDetails[index].price = prices.find(
        (x) => x.productId === productId
      ).price;
    }
    setTotals();
    setNewOrder((newOrder) => ({
      ...newOrder,
      orderDetails: orderDetails,
    }));
  };

  const handleNotesChange = (e) => {
    setNewOrder((newOrder) => ({
      ...newOrder,
      notes: e.target.value,
    }));
  };

  return (
    <Row type="flex" justify="space-between" align="middle" gutter={32}>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
        Select customer:
        <Select
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select customer"
          onChange={handleCustomerSelect}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {customers.map((customer) => (
            <Option value={customer.id} key={customer.id}>
              {customer.businessName}
            </Option>
          ))}
        </Select>
        Notes:
        <TextArea
          type="text"
          onChange={handleNotesChange}
          autoSize={{ minRows: 3, maxRows: 50 }}
        />
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 18 }}>
        <Button
          type="primary"
          className="green-btn"
          size="small"
          disabled={disabledAddButton}
          icon={<PlusOutlined />}
          onClick={handleAddProduct}
        ></Button>
        <List
          header="New order"
          style={{ width: "100%" }}
          itemLayout="horizontal"
          size="small"
          footer={"Total " + totalSum + " €"}
          dataSource={orderDetails}
          renderItem={(row, index) => (
            <List.Item key={row.id}>
              <List.Item.Meta
                title={
                  <Select
                    showSearch
                    allowClear
                    style={{ width: "80%" }}
                    placeholder="Please select product"
                    onChange={(value) => handleProductSelect(value, index)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {products.map((product) => (
                      <Option value={product.id} key={product.id}>
                        {ProductName(product)}
                      </Option>
                    ))}
                  </Select>
                }
              />
              <div style={{ marginRight: 12 }}>
                Price:{" "}
                <InputNumber
                  size="small"
                  min={0}
                  onChange={(value) => handlePriceChange(value, index)}
                  value={row.price}
                />
              </div>
              <div>
                Quantity:{" "}
                <InputNumber
                  size="small"
                  min={1}
                  onChange={(value) => handleQuantityChange(value, index)}
                  value={row.quantity}
                />
              </div>
              <Button
                type="primary"
                size="small"
                style={{ marginLeft: 4 }}
                danger
                onClick={() => handleOrderRowDelete(index)}
              >
                -
              </Button>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default NewOrder;
