import React, { useState, useEffect } from "react";
import { Row, Divider, message, List, Button, InputNumber, Select } from "antd";
import api from "../../Api";
import { ProductName } from "../Utils/Common";
import { PDFExport } from "@progress/kendo-react-pdf";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const OrderDetails = ({ order, customers }) => {
  const [products, setProducts] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [pdfExportComponent, setPdfExportComponent] = useState();
  const [deletedRows, setDeletedRows] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const [totalSum, setTotalSum] = useState(order.totalSum);
  const [showEditButtons, setShowEditButtons] = useState(false);
  const [prices, setPrices] = useState([]);

  const exportPDF = () => {
    pdfExportComponent.save();
  };

  const setTotals = () => {
    let total = orderDetails.reduce((a, b) => a + b.price * b.quantity, 0);
    setTotalSum(Number(total.toFixed(2)));
  };

  const handleOrderRowDelete = (rowId) => {
    let tempRows = [...deletedRows];
    tempRows.push(rowId);
    setDeletedRows(tempRows);
    orderDetails.filter((x) => x.id === rowId);
  };

  const handlePriceChange = (price, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.rowId === rowId)) {
      let index = tempRows.findIndex((x) => x.rowId === rowId);
      tempRows[index].price = price;
    } else {
      tempRows.push({ id: rowId, price: price });
    }
    orderDetails.find((x) => x.id === rowId).price = price;
    setChangedRows(tempRows);
    setTotals();
  };

  const handleQuantityChange = (quantity, rowId) => {
    let tempRows = [...changedRows];
    if (tempRows && tempRows.some((x) => x.id === rowId)) {
      let index = tempRows.findIndex((x) => x.id === rowId);
      tempRows[index].quantity = quantity;
    } else {
      tempRows.push({ id: rowId, quantity: quantity });
    }
    orderDetails.find((x) => x.id === rowId).quantity = quantity;
    setChangedRows(tempRows);
    setTotals();
  };

  const editOrder = () => {
    setShowEditButtons(true);
  };

  const updateOrder = async () => {
    let err = false;
    orderDetails.forEach((row, index) => {
      if (
        orderDetails.some(
          (x) =>
            x.productId === row.productId &&
            row.orderUid === "" &&
            x.orderUid !== ""
        )
      ) {
        message.error(
          `There is a product with the same name ${index + 1} , ${
            orderDetails.findIndex(
              (x) =>
                x.productId === row.productId &&
                row.orderUid === "" &&
                x.orderUid !== ""
            ) + 1
          }`,
          1
        );
        err = true;
      }
    });
    if (err) {
      return;
    }
    //for new rows
    orderDetails
      .filter((x) => x.orderUid === "")
      .forEach((row) => {
        row.orderUid = order.id;
        api
          .orders()
          .post(row)
          .then((res) => console.log(res.data))
          .catch((ex) => console.log(ex.data));
      });
    //for changed rows
    if (changedRows) {
      changedRows
        .filter((x) => x.id !== undefined)
        .forEach((row) => {
          api
            .orders(row.rowId)
            .post(row)
            .then((res) => console.log(res.data))
            .catch((ex) => console.log(ex.data));
        });
    }
    //for deleted rows
    if (deletedRows) {
      deletedRows
        .filter((x) => x.id !== undefined)
        .forEach((id) => {
          api
            .orders(id)
            .delete()
            .then((res) => console.log(res.data))
            .catch((ex) => console.log(ex.data));
        });
    }
    if (changedRows || deletedRows) {
      await api
        .documents()
        .post({ id: order.id, totalSum: totalSum })
        .then((res) => console.log(res.data))
        .catch((ex) => console.log(ex.data));
      message.success("Order updated", 1);
      //window.location.reload();
    }
  };

  useEffect(() => {
    const getOrderDetails = async () => {
      await api
        .orders(order.id)
        .get()
        .then((response) => setOrderDetails(response.data))
        .catch((err) => console.log(err));
    };
    const getProducts = async () => {
      await api
        .products()
        .get()
        .then((response) => {
          setProducts(response.data);
          setLoadingProducts(false);
        })
        .catch((err) => console.log(err));
    };
    const getPrices = async () => {
      await api
        .actualPrices(order.customerUid)
        .get()
        .then((res) => {
          setPrices(res.data);
        })
        .catch((err) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            console.log(err);
          }
        });
    };
    getOrderDetails();
    getProducts();
    getPrices();
  }, [order.id, order.customerUid, deletedRows]);

  const handleAddProduct = () => {
    let tmpRows = [...orderDetails];

    tmpRows.push({
      orderUid: "",
      productId: 0,
      quantity: "1",
      price: "0",
    });
    setOrderDetails(tmpRows);
  };

  const handleProductSelect = (productId, index) => {
    let tmpRows = [...orderDetails];

    tmpRows[index].productId = productId;
    if (prices.some((x) => x.productId === productId)) {
      tmpRows[index].price = prices.find(
        (x) => x.productId === productId
      ).price;
    }
    setOrderDetails(tmpRows);
    setTotals();
  };

  return (
    <>
      <PDFExport
        ref={(component) => setPdfExportComponent(component)}
        paperSize="A4"
        margin={40}
        fileName={`Report for ${new Date().getFullYear()}`}
        author="Owltech team"
      >
        <div>
          Order N:
          {order.id}
        </div>
        <div className="order-date">
          Order date:
          {" " +
            new Date(order.date).getFullYear() +
            "-" +
            (new Date(order.date).getMonth() + 1) +
            "-" +
            new Date(order.date).getDate()}
        </div>
        <div className="order-date">
          Customer:
          {" " + customers && customers.some((x) => x.id === order.customerUid)
            ? customers.find((x) => x.id === order.customerUid).businessName
            : ""}
        </div>
        <div className="order-date">
          Notes:
          {" " + order.notes ? order.notes : ""}
        </div>
        <div>
          {customers && customers.some((x) => x.id === order.customerUid)
            ? customers.find((x) => x.id === order.customerUid).address1 +
              " - " +
              customers.find((x) => x.id === order.customerUid).phone1
            : ""}
        </div>
        <Button
          type="primary"
          className="green-btn"
          size="small"
          disabled={!showEditButtons}
          icon={<PlusOutlined />}
          onClick={handleAddProduct}
        ></Button>
        <List
          style={{ width: "100%" }}
          itemLayout="horizontal"
          loading={loadingProducts}
          size="small"
          footer={"Total " + totalSum + " €"}
          dataSource={orderDetails}
          renderItem={(row, index) => (
            <List.Item key={index}>
              <List.Item.Meta
                title={
                  row.orderUid === "" ? (
                    <>
                      <span>{(index + 1).toString() + ". "}</span>
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "80%" }}
                        placeholder="Please select product"
                        onChange={(value) => handleProductSelect(value, index)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {products
                          .filter(({ id: id1 }) => !orderDetails.some(({ productId: id2, orderUid: id3 }) => id2 === id1 && id3 !== "" ))
                          .map((product) => (
                            <Option value={product.id} key={product.id}>
                              {ProductName(product)}
                            </Option>
                          ))}
                      </Select>
                    </>
                  ) : (
                    (index + 1).toString() +
                    ". " +
                    ProductName(products.find((x) => x.id === row.productId))
                  )
                }
              />
              <div style={{ marginRight: 12 }}>
                Price:{" "}
                {showEditButtons ? (
                  <InputNumber
                    size="small"
                    min={0}
                    onChange={(value) => handlePriceChange(value, row.id)}
                    value={row.price}
                    defaultValue={row.price}
                  />
                ) : (
                  row.price
                )}
              </div>
              <div>
                Quantity:{" "}
                {showEditButtons ? (
                  <InputNumber
                    size="small"
                    min={1}
                    onChange={(value) => handleQuantityChange(value, row.id)}
                    defaultValue={row.quantity}
                  />
                ) : (
                  row.quantity
                )}
              </div>
              <Button
                type="primary"
                size="small"
                style={{
                  marginLeft: 4,
                  display: showEditButtons ? "block" : "none",
                }}
                danger
                onClick={() => handleOrderRowDelete(row.id)}
              >
                -
              </Button>
            </List.Item>
          )}
        />
      </PDFExport>
      <Row type="flex" justify="center" align="middle">
        <Divider />
        <Button
          type="primary"
          onClick={showEditButtons ? updateOrder : editOrder}
          style={{ marginRight: 6 }}
        >
          {showEditButtons ? "Update order" : "Edit order"}
        </Button>
        <Button disabled={showEditButtons} onClick={exportPDF}>
          Download pdf
        </Button>
      </Row>
    </>
  );
};

export default OrderDetails;
