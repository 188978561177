import React, { useState, useEffect } from "react";
import {
  Row,
  Divider,
  Layout,
  Spin,
  Col,
  DatePicker,
  Select,
  message,
  List,
  Button,
  Drawer,
  Modal,
} from "antd";
import api from "../../Api";
import OrderDetails from "./OrderDetails";
import { PlusOutlined } from "@ant-design/icons";
import NewOrder from "./NewOrder";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [orderDrawer, setOrderDrawer] = useState((orderDrawer) => ({
    ...orderDrawer,
    visible: false,
  }));
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [newOrder, setNewOrder] = useState({});
  const [modal, setModal] = useState({
    ModalText: "Confirm order delete",
    visible: false,
    confirmLoading: false,
  });
  const [newOrderModal, setNewOrderModal] = useState({
    ModalText: "Add new order",
    visible: false,
    confirmLoading: false,
  });

  const handleCustomerSelect = async (value) => {
    setSelectedCustomer(value);
  };

  const onDateRangeChange = async (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handlePaymentStatus = async (value, orderUid) => {
    await api
      .documents()
      .post({
        id: orderUid,
        paymentStatus: value,
      })
      .then(message.success("Order's payment status updated", 1));
  };

  const handleFulfillmentStatus = async (value, orderUid) => {
    await api
      .documents()
      .post({
        id: orderUid,
        fulfillmentStatus: value,
      })
      .then(message.success("Order's fulfillment status updated", 1));
  };

  useEffect(() => {
    const getOrders = async () => {
      await api
        .documents()
        .get()
        .then((response) => {
          let tempOrders = [];
          if (selectedCustomer === undefined) {
            tempOrders = response.data;
          } else {
            tempOrders = response.data.filter(
              (x) => x.customerUid === selectedCustomer
            );
          }
          if (selectedDate && selectedDate[0] !== "") {
            let dateOne = Date.parse(selectedDate[0]);
            let dateTwo =
              selectedDate[0] !== selectedDate[1]
                ? Date.parse(selectedDate[1])
                : Date.parse(selectedDate[1]) + 86400000;
            tempOrders = tempOrders.filter(
              (x) =>
                Date.parse(x.date) > dateOne && Date.parse(x.date) < dateTwo
            );
          }
          setOrders(tempOrders);
          setSpinning(false);
        });
    };

    const getCustomers = async () => {
      await api
        .getCustomers()
        .get()
        .then((response) => setCustomers(response.data));
    };
    getOrders();
    getCustomers();
  }, [selectedCustomer, selectedDate]);

  const showOrderDrawer = (orderUid) => {
    setOrderDrawer({
      orderUid: orderUid,
      visible: true,
    });
  };

  const closeOrderDrawer = (orderUid) => {
    setOrderDrawer({
      orderUid: orderUid,
      visible: false,
    });
  };

  //order delete modal
  const showDeleteModal = (orderUid) => {
    setModal((modal) => ({
      ...modal,
      visible: true,
      orderUid: orderUid,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api
      .documents(modal.orderUid)
      .delete()
      .then((res) => console.log(res.data));
    await message.warning(`Order N ${modal.orderUid} successfully deleted`, 1);
    window.location.reload();
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  //New order modal
  const showNewOrderModal = () => {
    setNewOrderModal((newOrderModal) => ({
      ...newOrderModal,
      visible: true,
    }));
  };

  const handleNewOrderModalOk = async () => {
    setNewOrderModal((newOrderModal) => ({
      ...newOrderModal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setNewOrderModal((newOrderModal) => ({
        ...newOrderModal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    //store new order
    let total = newOrder.orderDetails.reduce(
      (a, b) => a + b.price * b.quantity,
      0
    );
    const documentData = {
      customerUid: newOrder.customerUid,
      notes: newOrder.notes,
      type: 1,
      paymentStatus: "pending",
      fulfillmentStatus: "pending",
      totalSum: Number(total.toFixed(2)),
    };
    await api
      .documents()
      .post(documentData)
      .then(async (response) => {
        newOrder.orderDetails.forEach(async (row) => {
          const data = {
            orderUid: response.data.id,
            productId: row.productId,
            quantity: row.quantity,
            price: row.price,
          };
          await api
            .orders()
            .post(data)
            .catch((error) => console.log(error));
        });
      })
      .catch((error) => console.log(error));
    await message.success(`New order added`, 1);
    window.location.reload();
  };

  const handleNewOrderModalCancel = () => {
    setNewOrderModal((newOrderModal) => ({
      ...newOrderModal,
      visible: false,
    }));
  };

  return (
    <div className="orders">
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>Orders</h1>
            <Row type="flex" justify="space-between" align="middle" gutter={32}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select customer"
                  onChange={handleCustomerSelect}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {customers.map((customer) => (
                    <Option value={customer.id} key={customer.id}>
                      {customer.businessName}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <RangePicker onChange={onDateRangeChange} />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 6 }}
                style={{ textAlign: "end" }}
              >
                <Button
                  type="primary"
                  className="green-btn"
                  onClick={showNewOrderModal}
                  icon={<PlusOutlined />}
                >
                  Add new
                </Button>
                <Modal
                  title={`Add new order`}
                  width="80%"
                  visible={newOrderModal.visible}
                  maskClosable={false}
                  onOk={handleNewOrderModalOk}
                  onCancel={handleNewOrderModalCancel}
                  footer={[
                    <Button key="back" onClick={handleNewOrderModalCancel}>
                      Cancel
                    </Button>,
                    <Button
                      className="green-btn"
                      key="submit"
                      type="primary"
                      loading={newOrderModal.confirmLoading}
                      onClick={handleNewOrderModalOk}
                      disabled={
                        newOrder.orderDetails === undefined ||
                        (newOrder.orderDetails &&
                          newOrder.orderDetails.length === 0) ||
                        (newOrder.orderDetails &&
                          newOrder.orderDetails.some((x) => x.productId === 0))
                      }
                    >
                      Add new order
                    </Button>,
                  ]}
                >
                  <NewOrder
                    setNewOrder={setNewOrder}
                    customers={customers}
                  />
                </Modal>
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="center" align="middle">
              <List
                style={{ width: "100%" }}
                itemLayout="horizontal"
                loading={spinning}
                bordered
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                }}
                dataSource={orders.sort((a, b) => new Date(b.date) - new Date(a.date))}
                renderItem={(order) => (
                  <List.Item key={order.id}>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={32}
                      style={{ width: "100%" }}
                    >
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 13 }}
                        lg={{ span: 13 }}
                      >
                        <List.Item.Meta
                          title={
                            customers &&
                            customers.some((x) => x.id === order.customerUid)
                              ? customers.find(
                                  (x) => x.id === order.customerUid
                                ).businessName
                              : ""
                          }
                          description={
                            order.totalSum + " € - Order N: " + order.id
                          }
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 2 }} lg={{ span: 2 }}>
                        <div className="order-date">
                          {new Date(order.date).getFullYear() +
                            "-" +
                            (new Date(order.date).getMonth() + 1) +
                            "-" +
                            new Date(order.date).getDate()}
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 3 }} lg={{ span: 3 }}>
                        <div className="order-status">Payment status:</div>
                        <Select
                          defaultValue={order.paymentStatus}
                          style={{ width: 120 }}
                          onChange={(e) => handlePaymentStatus(e, order.id)}
                        >
                          <Option value="pending">Pending</Option>
                          <Option value="payed">Payed</Option>
                        </Select>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 3 }} lg={{ span: 3 }}>
                        <div className="order-status" style={{ marginLeft: 4 }}>
                          Fulfillment status:
                        </div>
                        <Select
                          defaultValue={order.fulfillmentStatus}
                          style={{ width: 120, marginRight: 12 }}
                          onChange={(e) => handleFulfillmentStatus(e, order.id)}
                        >
                          <Option value="pending">Pending</Option>
                          <Option value="shipped">Shipped</Option>
                        </Select>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 3 }}
                        lg={{ span: 3 }}
                        style={{ textAlign: "right" }}
                      >
                        <Button onClick={() => showOrderDrawer(order.id)}>
                          Show
                        </Button>
                        <Button
                          type="primary"
                          style={{ marginLeft: 4 }}
                          danger
                          onClick={() => showDeleteModal(order.id)}
                        >
                          Delete
                        </Button>
                      </Col>
                      <Modal
                        title={`Are you sure delete order N ${modal.orderUid}?`}
                        visible={modal.visible}
                        onOk={handleModalOk}
                        confirmLoading={modal.confirmLoading}
                        onCancel={handleModalCancel}
                      >
                        <p>{modal.ModalText}</p>
                      </Modal>
                      <Drawer
                        title="View Order"
                        width={window.innerWidth < 576 ? "100%" : "50%"}
                        closable={true}
                        onClose={() => closeOrderDrawer(order.id)}
                        visible={
                          orderDrawer.visible &&
                          orderDrawer.orderUid === order.id
                        }
                      >
                        <OrderDetails order={order} customers={customers} />
                      </Drawer>
                    </Row>
                  </List.Item>
                )}
              />
            </Row>
            <Divider />
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Orders;
