import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CopyOutlined,
  DragOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  List,
  Layout,
  Button,
  Row,
  Col,
  Input,
  Tabs,
  message,
  Select,
  Checkbox,
} from "antd";
import ListSort from "../Utils/ListSort";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const ProductsDataView = () => {
  const [products, setProducts] = useState([]);
  const [nextId, setNextId] = useState();
  const [loading, setLoading] = useState(true);
  const [ordering, setOrdering] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [changedProduct, setChangedProduct] = useState({});
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      await api
        .products()
        .get()
        .then((res) => {
          setNextId(
            Math.max.apply(
              Math,
              res.data.map(function (o) {
                return o.id;
              })
            ) + 1
          );
          if (selectedFilters) {
            let tmpProducts = res.data;
            if (
              selectedFilters.category &&
              selectedFilters.category !== undefined
            ) {
              tmpProducts = tmpProducts.filter(
                (x) => x.categoryId === selectedFilters.category
              );
            }
            if (selectedFilters.isActive === true) {
              tmpProducts = tmpProducts.filter((x) => x.isActive);
            }
            if (selectedFilters.search) {
              tmpProducts = tmpProducts.filter(
                (product) =>
                  product.name &&
                  product.name
                    .find((x) => x.languageCode === languages[0].code)
                    .text.toUpperCase()
                    .includes(selectedFilters.search.toUpperCase())
              );
            }
            setProducts(tmpProducts);
          }
          setLoading(false);
        });
    };
    const getCategories = async () => {
      const { data } = await api.categories("isActive").get();
      setCategories(data);
    };
    getProducts();
    getCategories();
  }, [selectedFilters]);

  const handleOnSearch = (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      search: value,
    }));
  };

  const handleSaveOrdering = async () => {
    ordering.forEach((item, index) => {
      api.products().post({ id: item.props.product, ordering: index });
    });
    await message.success("Ordering successfully edited", 1);
    window.location.reload();
  };

  const handleCategoryChange = async (value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      category: value,
    }));
  };

  const handleisActiveChange = async (e) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      isActive: e.target.checked,
    }));
  };

  const onIsActiveChange = async (e, productId) => {
    setLoading(true);
    await api
      .products()
      .post({ id: productId, isActive: e.target.checked })
      .then(async (res) => {
        if (res.data.error) {
          await message.error(res.data.message, 2);
        } else {
          await message.success("This product successfully edited", 1);
          setChangedProduct(res.data);
          setLoading(false);
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  };

  const sortableProducts = products
    .filter((x) => x.favorite)
    .sort((a, b) => a.ordering - b.ordering)
    .map((item, i) => {
      return (
        <div key={i} product={item.id} className="list-sort-demo-list">
          <div className="list-sort-demo-icon">
            <DragOutlined />
          </div>
          <div className="list-sort-demo-text">
            <h1>{item.code}</h1>
            <p>{item.name ? item.name[0].text : ""}</p>
          </div>
        </div>
      );
    });
  const handleSortChange = (e) => {
    setOrdering(e);
  };

  const lineStyles = (product) => {
    if (!product.isActive) {
      return {
        background:
          "linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 231 231) 50%, rgb(255, 255, 255) 100%)",
      };
    }
    if (
      changedProduct &&
      changedProduct.id === product.id &&
      !changedProduct.isActive
    ) {
      return {
        background:
          "linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255 231 231) 50%, rgb(255, 255, 255) 100%)",
      };
    }
  };

  return (
    <Tabs className="dataview-nav" defaultActiveKey="1">
      <TabPane tab="All Products" key="1">
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <h1>All Products</h1>
            </Col>
            <Col span={6}>
              <Select
                showSearch
                allowClear
                style={{ width: 200 }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleCategoryChange}
                placeholder="Please select category"
              >
                {categories.map((cat) => (
                  <Option value={cat.id} key={cat.id}>
                    {cat.name &&
                    cat.name.some((x) => x.languageCode === languages[0].code)
                      ? cat.name.find(
                          (x) => x.languageCode === languages[0].code
                        ).text
                      : ""}
                  </Option>
                ))}
              </Select>
              <Checkbox
                onChange={handleisActiveChange}
                style={{ marginLeft: 12 }}
              >
                Is Active
              </Checkbox>
            </Col>
            <Col span={6}>
              <Search
                type="text"
                name="search"
                enterButton="Search"
                placeholder="input product name"
                onSearch={handleOnSearch}
              />
            </Col>
            <Col span={6}>
              <div className="addNew">
                <Link
                  to={{ pathname: `/product/?productID=${nextId}&isNew=true` }}
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    className="green-btn"
                  >
                    Add new
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <div
            className="products-list"
            style={{
              padding: 24,
              background: "#fff",
              textAlign: "left",
              borderRadius: 12,
            }}
          >
            <List
              itemLayout="horizontal"
              loading={loading}
              pagination={{
                onChange: (page) => {
                  localStorage.setItem("currentPage", page);
                },
                defaultCurrent: JSON.parse(localStorage.getItem("currentPage")),
              }}
              dataSource={products}
              renderItem={(product) => (
                <List.Item key={product.id} style={lineStyles(product)}>
                  <h2>{product.code}</h2>
                  <List.Item.Meta
                    title={
                      product.name &&
                      product.name.some(
                        (x) => x.languageCode === languages[0].code
                      )
                        ? product.name.find(
                            (x) => x.languageCode === languages[0].code
                          ).text
                        : ""
                    }
                  />
                  <Checkbox
                    defaultChecked={product.isActive}
                    onChange={(e) => onIsActiveChange(e, product.id)}
                  >
                    Is active
                  </Checkbox>
                  <Link
                    to={{ pathname: `/product/?copyProductID=${product.id}` }}
                  >
                    <CopyOutlined className="copy-icon" />
                  </Link>
                  <Link to={{ pathname: `/product/?productID=${product.id}` }}>
                    <Button>Show/Edit</Button>
                  </Link>
                </List.Item>
              )}
            />
          </div>
        </Content>
      </TabPane>
      <TabPane tab="Favorites" key="2">
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <h1>Favorites</h1>
            </Col>
          </Row>
          <div
            style={{
              padding: 24,
              background: "#fff",
              textAlign: "left",
              borderRadius: 12,
            }}
          >
            <div className="list-sort-demo-wrapper ">
              <div className="list-sort-demo">
                <Row type="flex" justify="end" align="middle">
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    onClick={handleSaveOrdering}
                  >
                    Save
                  </Button>
                </Row>
                <ListSort
                  dragClassName="list-drag-selected"
                  appearAnim={{
                    animConfig: { marginTop: [5, 30], opacity: [1, 0] },
                  }}
                  onChange={handleSortChange}
                >
                  {sortableProducts}
                </ListSort>
              </div>
            </div>
          </div>
        </Content>
      </TabPane>
    </Tabs>
  );
};
export default ProductsDataView;
