import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Layout,
  Col,
  message,
  Divider,
  Modal,
  Spin,
  Select,
  InputNumber,
  Form,
} from "antd";
import api from "../../Api";
import languages from "../Languages/languages.json";

const { Content } = Layout;
const { Option } = Select;

const CustomerPricesForm = ({ defaultValues, isNew }) => {
  const [inputs, setInputs] = useState(
    isNew
      ? {
          customerUid: "",
        }
      : defaultValues
  );
  const [modal, setModal] = useState({
    ModalText: "Confirm customer price document delete",
    visible: false,
    confirmLoading: false,
  });
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [customerPrices, setCustomerPrices] = useState([]);
  const [allCustomerPrices, setAllCustomerPrices] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    const getCustomerPrices = async () => {
      const { data } = await api.customerPrices(inputs.customerUid).get();
      setCustomerPrices(data);
    };
    const getAllCustomerPrices = async () => {
        const { data } = await api.customerPrices().get();
        setAllCustomerPrices(data);
      };
    if (inputs.customerUid) {
        getCustomerPrices();
    }
    else {
        getAllCustomerPrices();
      }
    setSpinning(false);
  }, [inputs.customerUid]);

  useEffect(() => {
    const getProducts = async () => {
      const { data } = await api.products().get();
      for (let j = 0; j < customerPrices.length; j++) {
        if (data.some((x) => x.id === customerPrices[j].productId)) {
          let index = data.findIndex((x) => x.id === customerPrices[j].productId);
          data.splice(index, 1);
        }
      }
      setProducts(data);
    };
    const getCustomers = async () => {
      const { data } = await api.getCustomers().get();
      if (isNew) {
        for (let j = 0; j < allCustomerPrices.length; j++) {
          if (data.some((x) => x.id === allCustomerPrices[j].customerUid)) {
            let index = data.findIndex(
              (x) => x.id === allCustomerPrices[j].customerUid
            );
            data.splice(index, 1);
          }
        }
      }
      setCustomers(data);
    };
    getProducts();
    getCustomers();
  }, [customerPrices, allCustomerPrices, isNew]);

  const handleSubmit = async (values) => {
    deleted.forEach(async (x) => {
      await api.prices(x).delete();
    });

    //check for duplicates
    let valueArrProducts = customerPrices.map(function (item) {
      return item.productId;
    });
    let isDuplicateProducts = valueArrProducts.some(function (item, idx) {
      return item === 0 ? false : valueArrProducts.indexOf(item) !== idx;
    });
    if (isDuplicateProducts) {
      await message.warning("Check for duplicate values", 1);
      return;
    }
    if (customerPrices.length === 0) {
      await message.warning("Discount grid is empty", 1);
      return;
    }
    let data = [];
    customerPrices.map(async (row) => {
      if 
        (row.productId === "")
       {
        data = [];
        await message.warning("Check for empty fields", 1);
        return;
      }
      data.push({
        id: row.id,
        customerUid: values.customerUid,
        productId: row.productId,
        price: row.price,
      });
    });
    data.forEach(async (row) => {
      await api
        .prices()
        .post(row)
        .then(async (res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    });
    if (data.length > 0) {
      if (isNew) {
        await message.success("This customer prices document successfully created", 1);
        history.push(`/cprices`);
        window.location.reload();
      } else {
        await message.success("This customer prices document successfully edited", 1);
        window.location.reload();
      }
    }
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);

    await api.customerPrices(inputs.customerUid).delete();
    await message.warning("This customer prices document successfully deleted", 1);
    history.push(`/cprices`);
  };
  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleCustomerChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      customerUid: value,
    }));
  };

  const addNewRow = () => {
    setCustomerPrices((prevRows) => [
      ...prevRows,
      { productId: "", price: 0 },
    ]);
  };

  const handleRemoveSpecificRow = (index, id) => async () => {
    customerPrices.splice(index, 1);

    setDeleted([...deleted, id]);
    setCustomerPrices((customerPrices) => [...customerPrices]);
  }; 

  const handleProductChange = (value, index) => {
    const _tempRows = [...customerPrices];
    _tempRows[index].productId = value;
    setCustomerPrices(_tempRows);
  };

  const handlePriceChange = (value, index) => {
    const _tempRows = [...customerPrices];
    _tempRows[index].price = value;
    setCustomerPrices(_tempRows);
  };

  return (
    <Form
      initialValues={{
        customerUid: inputs.customerUid,
      }}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Row type="flex" justify="start" align="middle" gutter={16}>
        <Col span={12}>
          <Form.Item
            name="customerUid"
            label="Customer"
            rules={[{ required: true, message: "Please select customer" }]}
          >
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              placeholder="Please select customer"
              onChange={handleCustomerChange}
              value={inputs.customerUid}
              disabled={isNew ? false : true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers.map((customer) => (
                <Option value={customer.id} key={customer.id}>
                  {customer.businessName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="center" align="middle">
        <Spin spinning={spinning} delay={300}>
          <table>
            {customerPrices && customerPrices.length > 0 ? (
              <thead>
                <tr>
                  <th>#</th>
                  <th style={{ opacity: 0 }}>id</th>
                  <th>Product</th>
                  <th>Price (€)</th>
                </tr>
              </thead>
            ) : (
              <thead></thead>
            )}
            <tbody>
              {customerPrices.map((customerPrice, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Input hidden disabled value={customerPrice.id} />
                  </td>
                  <td>
                    <Select
                      showSearch
                      style={{ width: 400 }}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={customerPrice.productId}
                      onChange={(value) => handleProductChange(value, index)}
                    >
                      {products.map((product) => (
                        <Option value={product.id} key={product.id}>
                          {product.name &&
                          product.name.some(
                            (x) => x.languageCode === languages[0].code
                          )
                            ? product.name.find(
                                (x) => x.languageCode === languages[0].code
                              ).text
                            : ""}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      name="price"
                      onChange={(value) => handlePriceChange(value, index)}
                      value={customerPrice.price}
                    />
                  </td>
                  <td>
                    <Button
                      type="danger"
                      shape="circle"
                      onClick={handleRemoveSpecificRow(index, customerPrice.id)}
                    >
                      -
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Row type="flex" justify="center" align="middle">
            <Button shape="circle" onClick={addNewRow}>
              +
            </Button>
          </Row>
        </Spin>
      </Row>
      <Divider />
      <Row justify="center" align="middle" type="flex" gutter={32}>
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isNew ? "Create" : "Update"}
            </Button>
          </Form.Item>
        </Col>
        <Col>
          {isNew ? (
            ""
          ) : (
            <Form.Item>
              <Button type="danger" onClick={showModal}>
                Delete
              </Button>
              <Modal
                title="Are you sure?"
                visible={modal.visible}
                onOk={handleModalOk}
                confirmLoading={modal.confirmLoading}
                onCancel={handleModalCancel}
              >
                <p>{modal.ModalText}</p>
              </Modal>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

const CustomerPrice = () => {
  const [customerPriceDoc, setCustomerPriceDoc] = useState({});
  const [spinning, setSpinning] = useState(true);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isNew = params.get("isNew") === "true";

  useEffect(() => {
    const fetchCustomerPrices = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let customer = params.get("customer");

      await api
        .customerPrices(customer)
        .get()
        .then((res) => {
          setCustomerPriceDoc(res.data[0]);
          setSpinning(false);
        })
        .catch((err) => console.log(err));
    };
    fetchCustomerPrices();
  }, []);

  return (
    <div className="customer-price">
      <Link className="back-icon" to="/cprices">
        <ArrowLeftOutlined />
      </Link>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>{isNew ? "Add New" : ""}</h1>
            <Divider />
            {isNew ? (
              <CustomerPricesForm defaultValues={customerPriceDoc} isNew={isNew} />
            ) : (
              !!customerPriceDoc.id && (
              <CustomerPricesForm defaultValues={customerPriceDoc} isNew={isNew} />
              )
            )}
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default CustomerPrice;
